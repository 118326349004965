import { get, post, put, Delete } from "../axios";

// SEA二维码管理-列表
export const infoSeaList = (params) => get("/web/info/sea/list", params);

// SEA二维码管理-编辑
export const infoSeaUpdate = (params) => put("/web/info/sea/update", params);

// SEA二维码管理-详情
export const infoSeaView = (id) => get("/web/info/sea/view/" + id, "");
